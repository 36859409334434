<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-4 pb-0">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <AddressElementSelect v-model="cityId"
                                  label="Населений пункт" filled
                                  select_type="city"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AddressElementSelect :parent_id="cityId" v-model="streetId"
                                  label="Вулиця(-ки)" filled
                                  select_type="street" use_parent_id
                                  :disabled="!cityId"
            />
          </v-col>
          <v-col cols="7" md="6">
            <AddressElementSelect :parent_id="streetId" v-model="buildingId"
                                  label="Будинок(-ки)" filled
                                  select_type="building" use_parent_id
                                  :disabled="!streetId"
            />
          </v-col>
          <v-col cols="5" md="6">
            <v-text-field type="text" :disabled="!buildingId"
                          hide-details filled label="№ Квартири" v-model="flatNumber" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Мешканець" v-model="fullName" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Особовий рахунок" v-model="personId" color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text class="mt-2">
      <v-btn depressed block color="success" @click.stop="searchFlats" class="mr-3">
        <v-icon left>mdi-account-search-outline</v-icon>
        Пошук
      </v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="px-6 pb-6 pt-0 mt-2">
      <h4 class="text-uppercase">Результат пошуку</h4>
      <div class="mt-2">
        <div style="display: flex; flex-wrap: wrap; border-radius: 3px"
             class="pa-2 grey--text text--darken-1 elevation-2 white mb-2">
          <div style="flex: 0 1 94px;">100000</div>
          <div style="flex: 60%">м. Тернопіль, вул. Петриківська 18 кв. 1</div>
          <div style="flex: 1">Буняк В.С.</div>
        </div>

        <div style="display: flex; flex-wrap: wrap; border-radius: 3px"
             class="pa-2 grey--text text--darken-1 elevation-2 white mb-2">
          <div style="flex: 0 1 94px;">100000</div>
          <div style="flex: 60%">м. Тернопіль, вул. Петриківська 18 кв. 1</div>
          <div style="flex: 1">Буняк В.С.</div>
        </div>

        <div style="display: flex; flex-wrap: wrap; border-radius: 3px"
             class="pa-2 grey--text text--darken-1 elevation-2 white mb-2">
          <div style="flex: 0 1 94px;">100000</div>
          <div style="flex: 60%">м. Тернопіль, вул. Петриківська 18 кв. 1</div>
          <div style="flex: 1">Буняк В.С.</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "HWP_Modal_Flat",
  props: ['title'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      fullName: '',
      personId: '',
      flatNumber: '',
      buildingId: 0,
      streetId: 0,
      cityId: 0,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    searchFlats() {

    }
  },
}
</script>
